.label {
  display: inline-block;
  width: 120px !important;
}

.propertyContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.propertyLabel {
  display: flex;
  align-items: center;
}

.propertyOverrideInfo {
  margin: 0 0 0 5px !important;
}

.propertyFormGroup {
  justify-content: space-between;
  margin: 0 !important;
  width: 100%;
}
