.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
}

.heading {
  margin-right: 20px !important;
}

.container,
.table {
  width: 100%;
}

.tableWrapper {
  height: 100%;
  overflow: scroll;
}

.cellValues {
  width: 50%;
}

.cellInnerValues {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
