.container {
  padding: 0 20px;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
}

.heading {
  margin-right: 20px !important;
}

.table {
  width: 100%;
}

.tableCell {
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cellField {
  width: 20%;
}

.values {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.cellName {
  width: 25%;
}

.cellDescription {
  width: 45%;
}

.categoryHeading {
  margin-top: 20px !important;
}
