.container {
  padding: 0 20px;
}

.form {
  margin: 0 0 20px 10px;
  width: 400px;
}

.propertiesHeading {
  margin-top: 20px !important;
}

.instructions {
  margin-bottom: 15px;
}
