.container {
  padding: 0 20px 20px 20px;
}

.form {
  padding-bottom: 20px;
  width: 300px;
}

.form {
  margin-top: 30px;
}
