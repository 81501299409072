.container {
  display: flex;
  flex-direction: column;
}

.actionBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 20px;
  height: 30px;
}

.breadcrumbContainer {
  width: 265px;
}

.breadcrumb {
  white-space: nowrap;
}

.currentBreadcrumb {
  display: inline-block !important;
  max-width: 205px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.switchMenuItem {
  padding: 3px;
}

.switch {
  margin: 0 !important;
}

.listContainer {
  flex: 1;
  margin: 5px 20px 20px 20px;
  min-width: 300px;
  width: auto;
}

.listContainerLight {
  border: 1px solid grey;
}

.listContainerDark {
  border: 1px solid white;
}

.list {
  display: inline-block;
  /* Better than float: left... */
  margin: 0;
  padding: 0;
  list-style: none;
  min-width: 100%;
}

.list li {
  padding-left: 5px;
  font-size: 16px;
  white-space: nowrap;
}

.listLight li {
  color: black;
}

.listDark li {
  color: white;
}

.list li:hover,
.active {
  background: grey;
}

.list>a:hover {
  text-decoration: none;
}

.ident {
  display: inline-block;
  width: 60px;
}

.projectIdent {
  width: 40px;
}
